// require("../vendor/typograph/viewer");

import { Controller } from "@hotwired/stimulus";
import { typograph_document } from "../vendor/typograph/viewer";

Stimulus.register(
  "typograph",
  class extends Controller {
    static targets = ["frame", "input"];

    static values = {
      canvasId: String,

      previewPrefix: String,
      pagePrefixes: Array,

      // e.g. Object { hex: "#000000", hsl: "0,0%,0%", rgb: "0,0,0" }
      colorMain1: Object,
      colorMain2: Object,
      colorSub1: Object,
      colorSub2: Object,
      colorSub3: Object,

      // e.g. Object { url: "https://..." }
      fontMain1: Object,
      fontSub1: Object,
      fontSub2: Object
    }

    connect() {
      this.setTextVariableValueAbortController = null;
      this.setTextVariableValuePID = null;

      this.loadEditor();
      this.enableSaveOnSubmit();

      this.element.addEventListener('classlist:changed', this.setDimensions.bind(this));
      this.assignRangeQueue = [];
    }

    disconnect() {
      this.element.removeEventListener('classlist:changed', this.setDimensions.bind(this));
    }

    setDimensions(_event) {
      // make sure we reduce our canvas element ...
      this.canvasElement.style.width = "100px";
      this.canvasElement.style.height = "100px";

      /// ... before we set the frame to whatever is available for us
      this.canvasElement.style.width = this.frameTarget.clientWidth + "px";
      this.canvasElement.style.height = this.frameTarget.clientHeight + "px";

      // notify Typograph about this change, so it can redraw
      if (this.typograph_document) {
          this.typograph_document.resize_main_canvas();
      }
    }

    loadEditor() {
      this.canvasElement = document.createElement("canvas");
      this.canvasElement.id = this.canvasIdValue;
      this.setDimensions()

      this.canvasElement.addEventListener("typograph_ready", (event) => {
        this.editorReady(event);
      });

      this.canvasElement.addEventListener("typograph_document_opened", (event) => {
        this.typographDocumentOpened(event);
      });

      this.canvasElement.addEventListener("typograph_page_images_loaded", (event) => {
        this.typographPageImagesLoaded(event);
      });

      this.canvasElement.addEventListener("typograph_textInput", (event) => {
        const textVariableInput = this.element.querySelector(`[data-typograph-target-param*="${event.detail.uuid}"]`);

        if (textVariableInput && textVariableInput.value !== event.detail.text) {
          textVariableInput.value = event.detail.text;

          // also push it back to Typograph just in case this variable has multiple UUID's
          this.setTextVariableValue(textVariableInput.dataset.typographTargetParam, textVariableInput.value);
        } else {
          console.warn(`No element found with data-typograph-target-param="${event.detail.uuid}"`);
        }
      });

      this.frameTarget.appendChild(this.canvasElement);

      let setup = { canvas_id: this.canvasIdValue, document_url: "", user_id: "", viewer_only: true };
      this.typograph_document = new typograph_document(setup);
      window.g_typograph_document = this.typograph_document;
      this.typograph_document.setup_fontmanager();
      this.typograph_document.setup_documentmanager();
      this.typograph_document.setup_scollbars();
      this.typograph_document.setup_zoom();
      this.typograph_document.setup_undo_redo();
      this.typograph_document.setup_page_bar();
      this.typograph_document.setup_documentmousemanager();
      this.typograph_document.setup_menushortcuts();
      this.typograph_document.setup_undomanager();
      this.typograph_document.setup_dialogmanager("typograph_viewer");
    }

    editorReady() {
      this.typograph_document.open_document(JSON.parse(this.inputTarget.value));
    }

    typographDocumentOpened() {
      this.updateTextVariables();
      this.setPageVisibility()
    }

    typographPageImagesLoaded() {
      if (this.imagePlaceholdersApplied) { return; }

      this.imagePlaceholdersApplied = true;
      this.updateImagePlaceholders();
    }

    updateTextVariables() {
      this.element
        .querySelectorAll('*[data-action*="pushTextVariable"]')
        .forEach((textVariableInput) => {
          this.setTextVariableValue(textVariableInput.dataset.typographTargetParam, textVariableInput.value);
        });
    }

    updateImagePlaceholders() {
      // update all placeholders
      let placeholdersNodelist = this.element.querySelectorAll('input[data-action="change->typograph#pushImagePlaceholder"][value]');
      let placeholders = [...placeholdersNodelist];

      placeholders.forEach(placeholder => {
        let uuid = placeholder.attributes['data-typograph-target-param'].value;

        this.typograph_document.pages.forEach(page => {
          let page_element = page.getElementByUUID(uuid);
          if (page_element) {
            var params = {};
            let placeholder_attributes = placeholder.attributes;

            [...placeholder_attributes].forEach(param => {
              let param_match = param.name.match(/^data-typograph-(.*)-param$/);
              if (param_match) {
                params[this.camelize(param_match[1])] = this.typecast(param.value);
              }

              if (param.name == 'data-typograph-current-placeholder') {
                params['placeholder'] = JSON.parse(param.value).data;
              }
            })

            this.pushImagePlaceholderValue(placeholder, params);
          }
        });
      });
    }

    pushTextVariable(event) {
      this.setTextVariableValue(event.params.target, event.target.value);
    }

    async setTextVariableValue(target, value) {
      if (this.setTextVariableValueAbortController) { this.setTextVariableValueAbortController.abort(); }
      if (this.setTextVariableValuePID) { clearTimeout(this.setTextVariableValuePID); }

      const controller = new AbortController();
      this.setTextVariableValueAbortController = controller;

      this.setTextVariableValuePID = setTimeout(() => {
        try {
          if (controller.signal.aborted) return;

          let uuids = typeof (target) == 'string' ? JSON.parse(target) : target;

          uuids.forEach(uuid => {
            this.typograph_document.pages.forEach(page => {
              let pageElement = page.getElementByUUID(uuid);

              if (pageElement) { pageElement.set_text(value); }
              if (controller.signal.aborted) return;
            });
          });

          if (controller.signal.aborted) return;
          this.typograph_document.redraw();

          this.setTextVariableValuePID = null;
        } catch (error) {
          if (!controller.signal.aborted) { throw error; }
        } finally {
          if (this.setTextVariableValueAbortController === controller) {
            this.setTextVariableValueAbortController = null;
          }
        }
      }, 250);
    }

    pushImagePlaceholder(event) {
      let placeholder = event.target;
      let params = event.params;

      this.pushImagePlaceholderValue(placeholder, params);
    }

    pushImagePlaceholderValue(placeholder, params) {
      let target = params.target;
      let value = placeholder.value;

      if (placeholder.dataset.processRange && params.rangeSelect) {
        let queueItem = {
          placeholder: placeholder,
          params: params
        }

        if (this.assignRangeQueue.filter(queuedItem => queuedItem.placeholder.id == queueItem.placeholder.id).length == 0) {
          this.assignRangeQueue[this.assignRangeQueue.length] = queueItem;
          this.processAssignRangeQueue();
        }
      } else if (params.placeholder) {
        let placeholder = params.placeholder;
        let url = this.populateUrlParams(params.url, placeholder, value);

        this.applyImagePlaceholderValue({
          valuePresent: !!value,
          target: target,
          url: url
        });
      }
    }

    processAssignRangeQueue() {
      if (this.pushImagePlaceholderRangeValueRunning) {
        return;
      }

      let nextItem = this.assignRangeQueue.pop();

      if (nextItem) {
        this.pushImagePlaceholderRangeValue(nextItem.placeholder, nextItem.params);
      } else {
        this.pushImagePlaceholderRangeValueRunning = false;
      }
    }

    pushImagePlaceholderRangeValue(placeholder, params) {
      this.pushImagePlaceholderRangeValueRunning = true;
      let target = params.target;
      let value = placeholder.value;

      fetch(
        placeholder.dataset.typographRangeAssignPath,
        {
          method: 'PATCH',
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          body: JSON.stringify({
            authenticity_token: this.element.querySelector('input[name="authenticity_token"]').value,
            value: value,
          }),
        }
      ).then((response) => response.json())
        .then((json) => {
          let file = json.files[0];

          if (!file) {
            this.applyImagePlaceholderValue({
              valuePresent: false,
              target: target
            });

            this.pushImagePlaceholderRangeValueRunning = false;
            this.processAssignRangeQueue();
            return
          }

          let placeholder = file.image_library_link_attributes.data;
          let url = this.populateUrlParams(params.url, placeholder, file.name);

          this.applyImagePlaceholderValue({
            valuePresent: true,
            target: target,
            url: url
          });

          this.pushImagePlaceholderRangeValueRunning = false;
          this.processAssignRangeQueue();
        });
    }

    applyImagePlaceholderValue({ valuePresent, target, url }) {
      let uuids = typeof (target) == 'string' ? JSON.parse(target) : target;

      uuids.forEach(uuid => {
        this.typograph_document.pages.forEach(page => {
          let pageElement = page.getElementByUUID(uuid);
          if (pageElement) {
            if (valuePresent) {
              pageElement.hidden = false;
              pageElement.set_image_scale('ScaleToFit');
              pageElement.set_url(url);
            } else {
              pageElement.hidden = true;
              this.typograph_document.redraw();
            }
          }
        });
      });
    }

    populateUrlParams(url, placeholder, value) {
      return url
        .replaceAll("/:s/", `/${encodeURIComponent(placeholder.secret)}/`)
        .replaceAll("/:path", `/${encodeURIComponent(value)}`);
    }

    setPageVisibility() {
      let visiblePageIndices = this.prefixPageIndices(this.previewPrefixValue)

      if (visiblePageIndices.length > 0) {
        this.typograph_document.set_visible_page_indexes(visiblePageIndices)
        this.typograph_document.set_active_page(visiblePageIndices[0])
      }
    }

    prefixPageIndices(targetPrefix) {
      return this.pagePrefixesValue
        .filter(item => item.prefix === targetPrefix)
        .map(item => item.page_index);
    }

    setAlternateLayout({ params: { target } }) {
      this.previewPrefixValue = target;
      this.setPageVisibility();
    }

    enableSaveOnSubmit() {
      this.element.addEventListener("submit", (event) => {
        this.saveAndSubmit(event);
      });
    }

    saveAndSubmit(_event) {
      this.inputTarget.value = this.typograph_document.get_document_data_as_string();

      return true;
    }

    // Taken from https://github.com/hotwired/stimulus/blob/8cbca6db3b1b2ddb384deb3dd98397d3609d25a0/src/core/action.ts
    // because we need to construct event params in updateImagePlaceholders
    camelize(value) {
      return value.replace(/(?:[_-])([a-z0-9])/g, (_, char) => char.toUpperCase());
    }

    typecast(value) {
      try {
        return JSON.parse(value);
      } catch (o_O) {
        return value;
      }
    }
  }
);
